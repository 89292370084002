<template>
  <div class="jumbotron">
    <b-container class="mt-4">
      <h1 class="my-4">{{ $t('搜索') }}</h1>
      <b-input-group class="row">
        <b-input
          class="col-12 col-md-5 ml-3 mr-md-2"
          size="md"
          v-model="searchText"
          @keyup.enter="handleSearch"
        ></b-input>
        <button class="btn col-2" @click="handleSearch" style="padding: 0">
          {{ $t('搜索') }}
        </button>
      </b-input-group>
      <b-tabs content-class="mt-3" class="search-tabs my-4" lazy justified align="center" v-model="tabsId">
        <b-tab>
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-center">
              <strong class="mx-2">{{ $t('頁面') }}</strong>
            </div>
          </template>
          <template>
            <b-overlay :show="loading" rounded="sm">
              <template v-if="searchList">
                <div class="news-list my-4" v-if="searchList.total > 0">
                  <h2>{{ $t('搜索結果') }}：</h2>
                  <ul class="d-flex flex-wrap mx-0">
                    <li class="col-12 col-md-12 p-0" v-for="item in searchList.list" :key="`list_${item.title}`">
                      <b-link rel="noopener noreferrer" :to="item.path == 'home' ? '/' : item.path" :target="'_blank'">
                        <div class="d-flex flex-wrap pb-3 content_title align-items-center">
                          <!-- <div class="col-12 col-md-2 p-0">
                  <b-img
                    :src="
                      item.cover
                        ? item.cover
                        : `${require('@/images/s_banner0.png')}`
                    "
                  />
                </div> -->
                          <div class="col-12 col-md-12 my-auto item_text">
                            <h4 style="color: #0b69be">{{ item.title }}</h4>
                            <p class="sub-title mr-md-5" v-html="item.content + '...'"></p>
                          </div>
                        </div>
                      </b-link>
                    </li>
                  </ul>
                </div>
                <h4 class="text-center mt-5" v-else>{{ $t('無記錄') }}</h4>
              </template>
              <template v-if="searchList">
                <div class="text-center my-5" v-if="searchList.total / curNum > curpage">
                  <a rel="" class="btn btn-round news_more" @click="handleMore">{{ $t('查看更多') }} + </a>
                </div>
              </template>
            </b-overlay>
          </template>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-center">
              <strong class="mx-2">{{ $t('新聞') }}</strong>
            </div>
          </template>
          <template>
            <b-overlay :show="loading" rounded="sm">
              <template v-if="searchList">
                <div class="news-list my-4" v-if="searchList.total > 0">
                  <h2>{{ $t('搜索結果') }}：</h2>
                  <ul class="d-flex flex-wrap mx-0">
                    <li class="col-12 col-md-12 p-0" v-for="(item, key) in searchList.list" :key="`list_news_${key}`">
                      <b-link
                        :to="
                          tabsId == 0
                            ? item.path == 'home'
                              ? '/'
                              : item.path
                            : { name: 'news-detail', params: { id: item.id } }
                        "
                        :target="tabsId == 0 ? '_blank' : '_self'"
                      >
                        <div class="d-flex flex-wrap pb-3 content_title align-items-center">
                          <!-- <div class="col-12 col-md-2 p-0">
                  <b-img
                    :src="
                      item.cover
                        ? item.cover
                        : `${require('@/images/s_banner0.png')}`
                    "
                  />
                </div> -->
                          <div class="col-12 col-md-12 my-auto item_text">
                            <h4 style="color: #0b69be">{{ item.title }}</h4>
                            <p class="sub-title mr-md-5" v-html="item.content + '...'"></p>
                          </div>
                        </div>
                      </b-link>
                    </li>
                  </ul>
                </div>
                <h4 class="text-center mt-5" v-else>{{ $t('無記錄') }}</h4>
              </template>
              <template v-if="searchList">
                <div class="text-center my-5" v-if="searchList.total / curNum > curpage">
                  <a rel="" class="btn btn-round news_more" @click="handleMore">{{ $t('查看更多') }} + </a>
                </div>
              </template>
            </b-overlay>
          </template>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  data() {
    return {
      tabsId: 0,
      loading: false,
      searchText: '',
      curpage: 1,
      searchList: {},
      curNum: 10,
      search: null,
      title: null
    }
  },
  computed: {
    ...mapState('page', ['searchs']),
    // ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    },
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {
    tabsId(o, n) {
      // console.log(1111,o,n)
      this.handleTab()
    }
  },
  methods: {
    fetchData() {
      // let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      // path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, text } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          // this.$store.dispatch('common/FETCH_PAGE', {
          //   path,
          //   cookies,
          //   preview,
          //   id
          // }),
          this.$store.dispatch('page/FETCH_SEARCH', {
            cookies,
            type: 'page',
            text: text,
            page: 1,
            limit: 10
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      if (await this.searchs) {
        this.searchList = this.searchs
      }
    },
    handleMore() {
      return new Promise((resolve, reject) => {
        this.curpage += 1
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('page/FETCH_SEARCH', {
            type: this.tabsId == 0 ? 'page' : 'news',
            text: this.searchText ? this.searchText.replace(/\s+/g, '') : '',
            page: this.curpage,
            limit: 10
          })
        ])
          .then(() => {
            this.searchList.list = this.searchList.list.concat(this.searchs.list)
            resolve()
          })
          .catch(err => {
            reject()
          })
      })
    },
    handleTab() {
      this.handleSearch()
    },
    handleSearch() {
      if (this.searchText) {
        this.loading = true
        if (this.searchText != this.$route.query.text) {
          this.$router.push({
            name: 'search',
            query: {
              text: this.searchText.replace(/\s+/g, '')
            }
          })
        }
        this.curpage = 1
        Promise.all([
          this.$store.dispatch('page/FETCH_SEARCH', {
            type: this.tabsId == 0 ? 'page' : 'news',
            text: this.searchText.replace(/\s+/g, ''),
            page: this.curpage,
            limit: 10
          })
        ])
          .then(() => {
            this.searchList = this.searchs
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  mounted() {
    const { text } = this.$route.query
    this.searchText = text
    this.searchList = this.search
    this.fetchData()
  },
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
